<template>
  <div>
    <management-tags />
  </div>
</template>

<script>
import ManagementTags from './components/ManagementTags.vue'

export default {
  components: { ManagementTags },
}
</script>

<style lang="scss" scoped></style>
