<template>
  <div>
    <button-widget
      text="เพิ่มหมวดหมู่วอลเปเปอร์"
      block
      variant="primary"
      class="mb-2"
      :disabled="loading || langsSupport.length === 1"
      @click="addTag"
    />
    <b-card>
      <table-local-v-1-widget
        v-if="langsSupport.length > 1"
        :items="items"
        :columns="cColumns"
        :actions="actions"
        :loading="loading"
        title="จัดการหมวดหมู่วอลเปเปอร์"
        @clickEdit="editTag"
        @clickDelete="callApiToDelete"
      />
      <p v-else class="text-center m-0">กำลังค้นหาประเทศ โปรดรอสักครู่...</p>
    </b-card>

    <b-modal
      ref="refModalManagementTags"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :title="cModalTitle"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="onModalConfirm"
    >
      <b-form-group v-for="lang in langsSupport" :key="lang.local_code">
        <label :label-for="`tagName-${lang.local_code}`">
          <span class="text-uppercase">{{ lang.flag }}({{ lang.local_code }})</span> หมวดหมู่วอลเปเปอร์
        </label>
        <small v-if="lang.local_code === 'th'" class="text-danger"> *จำเป็นต้องระบุ</small>
        <small v-else> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
        <b-form-input v-model="form.tag_name_translations[lang.local_code]" />
      </b-form-group>
      <!-- <pre>{{ form }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      items: [],
      loading: false,
      columns: [
        {
          label: 'หมวดหมู่วอลเปเปอร์',
          field: 'tag_name',
        },
      ],
      actions: [
        { keyEmit: 'clickEdit', label: 'แก้ไข', iconName: 'EditIcon' },
        { keyEmit: 'clickDelete', label: 'ลบ', iconName: 'TrashIcon' },
      ],
      form: {
        tag_name_translations: {},
      },
    }
  },
  computed: {
    ...mapState('app-mobile-public-configs', ['langsSupport']),

    cModalTitle() {
      if (this.form?.id) return 'แก้ไขหมวดหมู่วอลเปเปอร์'
      return 'เพิ่มหมวดหมู่วอลเปเปอร์'
    },

    cDefaultTranslationObj() {
      const defaultKeysTranslationObj = {}

      this.langsSupport.forEach(v => {
        defaultKeysTranslationObj[v.local_code] = ''
      })
      return defaultKeysTranslationObj
    },
    cColumns() {
      const listColumns = this.langsSupport.map(lang => ({
        label: `${lang.flag} (${lang.local_code}) หมวดหมู่วอลเปเปอร์`.toUpperCase(),
        field: lang.local_code === 'th' ? 'tag_name' : `tag_name_translations.${lang.local_code}`,
      }))

      return listColumns
    },
  },
  async created() {
    await this.fetchAppLangsSupport()
    await this.getAllTags()
  },
  methods: {
    ...mapActions('app-mobile-public-configs', ['fetchAppLangsSupport']),
    addTag() {
      this.form = {
        tag_name: '',
        id: null,
        tag_name_translations: { ...this.cDefaultTranslationObj },
      }
      this.$refs.refModalManagementTags.show()
    },
    deleteTag() {},
    editTag(data) {
      this.form = {
        ...data,
        tag_name_translations: { ...this.cDefaultTranslationObj, th: data.tag_name, ...data?.tag_name_translations },
      }
      this.$refs.refModalManagementTags.show()
    },
    async getAllTags() {
      this.loading = true
      const resp = await this.api.getV2('api/admin/admin-topic-image-tags', this).catch(() => null)
      if (resp && resp.code === 200) {
        this.items = [...resp.data]
      }
      this.loading = false
    },
    async onModalConfirm(bvEvent) {
      await bvEvent.preventDefault()

      if (!this.form.tag_name_translations.th) {
        this.gDisplayToast('โปรดระบุหมวดหมู่วอลเปเปอร์', '', 'danger')
        return
      }
      this.$refs.refModalManagementTags.hide()

      this.loading = true

      const newForm = {
        ...this.form,
        tag_name: this.form.tag_name_translations.th,
      }
      if (this.form?.id) {
        await this.callApiToUpdate(newForm)
      } else {
        await this.callApiToCreate(newForm)
      }
      this.loading = false
    },
    async callApiToCreate(values) {
      // console.log('callApiToCreate', values)
      // return
      const resp = await this.api.postV2('api/admin/admin-topic-image-tags', values, this)

      if (resp && resp.code === 200) {
        await this.getAllTags()
        this.gDisplayToast('เพิ่มข้อมูลสำเร็จแล้ว')
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', resp?.data?.message, 'danger')
      }
    },
    async callApiToUpdate(values) {
      // console.log('callApiToUpdate', values)
      // return
      const resp = await this.api.putV2(`api/admin/admin-topic-image-tags/${values.id}`, values, this)

      if (resp && resp.code === 200) {
        await this.getAllTags()
        this.gDisplayToast('แก้ไขข้อมูลสำเร็จแล้ว')
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', resp?.data?.message, 'danger')
      }
    },
    async callApiToDelete(item) {
      const resp = await this.api.deleteV2(`api/admin/admin-topic-image-tags/${item.id}`, this)
      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp?.message, 'danger')
      } else {
        this.gDisplayToast('ลบข้อมูลสำเร็จแล้ว')
        this.items = [...this.items].filter(v => v.id !== item.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
